/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import '../../../styles/Admin/dashboardCards.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRectangleList, faCubesStacked, faUsersGear} from '@fortawesome/free-solid-svg-icons'
import homeAdminContext from '../../../context/homeAdmin/homeAdminContext'
import { CarouselScreen } from './CarouselScreen'
import { UsScreen } from './UsScreen'
import { ServicesScreen } from './ServicesScreen'
export const Cards = () => {

   const {homeAdminState} = useContext(homeAdminContext)
   const [screen, setscreen] = useState('')
    useEffect(() => {
      
    }, [homeAdminState.activeScreen])    
    
    
  return (
    <>
    <div  className="app">
        <ul className="list" style={{display: "contents"}}>
            <li style={{display:"block"}}  onClick={()=>setscreen('carousel')}>
                <a className="card tile text-dark">
                    <div className="card-header topper"></div>
                    <div className="card-body flex-column">
                        <div className="display-4 Title">Carousel</div>
                        <FontAwesomeIcon className="fontAwesomeIcon" icon={ faRectangleList} />
                    </div>
                </a>
            </li>
            <li style={{display:"block"}} onClick={()=>setscreen('services')}>
                <a className="card tile text-dark">
                    <div className="card-header topper"></div>
                    <div className="card-body flex-column">
                        <div className="display-4 Title">Servicios</div>
                        <FontAwesomeIcon className="fontAwesomeIcon" icon={ faCubesStacked} />
                    </div>
                </a>
            </li>
            <li style={{display:"block"}} onClick={()=>setscreen('us')}>
                <a className="card tile text-dark">
                    <div className="card-header topper"></div>
                    <div className="card-body flex-column">
                        <div className="display-4 Title">Nosotros</div>
                        <FontAwesomeIcon className="fontAwesomeIcon" icon={ faUsersGear} />
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <div>
        {
            screen==='' && <div className='animated-container' style={{backgroundImage: `url("https://i.pinimg.com/originals/87/4f/84/874f84e09dd02f50364b278c1bb64d32.gif")` }}></div>
        }
        {
            screen==='carousel' && <CarouselScreen />
        }
        {
            screen==='services' && <ServicesScreen />
        }
        {
            screen==='us' && <UsScreen />
        }
    </div>
    </>
  )
}
