import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import homeAdminContext from '../../../context/homeAdmin/homeAdminContext'
import { ModalImages } from './ModalImages'
export const ServicesScreen = () => {
  const {
    handleShow,  
    changeScreen,
    imageServices
    } = useContext(homeAdminContext)
  const [ImageServices, setimageServices] = useState(imageServices);
  useEffect(() => {
    setimageServices(imageServices)
  }, [setimageServices, imageServices])
  
  /***Modal *****/
  const actionModal=async()=>{
        await changeScreen('servicesImage'); 
        await handleShow();
   
  } 
  const actionModalEditar=async()=>{
    await changeScreen('servicesEditar'); 
    await handleShow();

} 
return (
<>
{
ImageServices===null || ImageServices===undefined ?
<div className='agregar' style={{padding:'2em', color:'#fff'}}>Tamaño recomendado: 1600x900px<FontAwesomeIcon className="AddButton"icon={ faPlusSquare} onClick={()=>actionModal()}/></div>
:

<main class="carousel-container">
         <section class="cardCarousel"   style={{backgroundImage: `url(${ImageServices.url})` }}>
            
             <div class="texts">
                 <button type="button" className='editar' onClick={()=>actionModalEditar()}><FontAwesomeIcon  icon={ faEdit} />Editar</button>
            </div>
         </section>
</main>
 }
<ModalImages />
</>)
}
