import React, { useContext, useState } from 'react';
import AdminContext from '../../context/admin/adminContext';
import { collection, addDoc, doc, updateDoc} from "firebase/firestore";
import { useForm } from '../../hooks/useForm';
import { db, storage } from '../../firebase/firebase-config';
import { ref, uploadBytesResumable, getDownloadURL, } from 'firebase/storage'
import { v4 } from 'uuid'
import Swal from "sweetalert2";
import { myDate } from '../../helpers/Date';

export const ServiceForm = () => {
    const {handleClose, adminState, editFirebaseService} = useContext(AdminContext)
    const {modalScreen, service}=adminState;
    const [imageUpload, setimageUpload] = useState(null)
    
    // manejo del formulario
    let initialState={name:'', description: ''}
    if(modalScreen==='editarServicio'){
        initialState={name:service.name, description: service.description}
    }
    let [formValues, handleInputChange, reset]=useForm(initialState);
    let {name, description}=formValues;
    
    const closeModal=async(e)=>{
        e.preventDefault();
        await handleClose()
    }
    const resetAndClose=()=>{
        setimageUpload(null)
        reset({name:'', description: ''});
        handleClose()
    }
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        
        if(name.trim() ==='' || description.trim()===''){
            Swal.fire("Error", "Todos los campos son obligatorios", "error");
            return
        }
        //Comprobar que no se esté agregando imagen al editar
        if(modalScreen==='editarServicio' && imageUpload ===null){
            editFirebaseService({name, description, id:service.id});
            resetAndClose();
        //Si se está creando un nuevo registro
        }else if(modalScreen==='agregarServicio'){
            if(imageUpload===null){
                Swal.fire("Error", "Debe agregar una imagen", "error");
                return
            }
            const date=myDate().split(" ")[0].split("/").reverse().join("/");
            //creando el registro del servicio
            addDoc(collection(db, "services"), {
                name,
                description,
                date
            }).then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `services/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, 'services', docum.id);
                                    updateDoc(docuRef, {photo:URL})
                                    .then(() => {
                                        Swal.fire("Servicio guardado", "Servicio guardado con éxito", "success");
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    resetAndClose();
                                });
                        }
                    )
    
                };
            });    
        //si se está editando un registro y guardando una imagen
        }else{
            //obteniendo la referencia al registro
            const docuRef =doc(db, 'services', service.id);
            updateDoc(docuRef, {name, description})
            .then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `services/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, 'services', service.id);
                                    updateDoc(docuRef, {photo:URL})
                                    .then(() => {
                                        Swal.fire("Edición de servicio", "Servicio actualizado con éxito", "success");
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    resetAndClose();
                                });
                        }
                    )
    
                };
            });    
        }
    }
  return (
    <>
    <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
            <input type="text" className="form-control"name="name" placeholder="Nombre del servicio" value={name} onChange={handleInputChange}/>
        </div>
        <div className="col-12">
            <label className="form-label">Descripción: </label>
            <textarea  className="form-control" type="message" rows="4" value={description} name="description" onChange={handleInputChange}></textarea>
        </div>
        {
        modalScreen==='editarServicio' &&
        (
        <div className="col-12">
            <label className="form-label">Imagen actual: </label>
            <div  style={{backgroundImage: `url(${service.photo})` }} className='image-form-container'>
            
            </div>
        </div>
        )
        }
        <div className="col-12">
            <label className="form-label">Imagen(tamaño recomendado 250x220px): </label>
            <input type="file" className="form-control" name="photo" onChange={(event)=>{setimageUpload(event.target.files[0])}}/>
        </div>
        
        <div className="col-12" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <button type="submit" className="btn btn-primary btnAccept">{modalScreen==='editarServicio' ? 'Editar' : 'Agregar'}</button>
            
            <button className="btn btn-primary btnCancel" onClick={closeModal}>
                Cancelar
            </button>
        </div>
    </form>
    
    </>
  )
}
