import React, { useContext, useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import homeAdminContext from '../../context/homeAdmin/homeAdminContext';
import '../../styles/Home/carousel.css'

export const CarouselComponent = () => {
  const {sortedCarousel} = useContext(homeAdminContext)
  const [images, setImages] = useState(sortedCarousel)
  useEffect(() => {
    setImages(sortedCarousel)
  }, [setImages, sortedCarousel])
  
  return (
    <Carousel className="carousel" >
     {
            (images!==null) &&
              images.map((image) =>{
                return (
                  <Carousel.Item className="item" key={image.id}>
                    <img
                      className="d-block w-100"
                      src={image.url}
                      alt="First slide"
                    />
                    <Carousel.Caption className="caption">
                      <h3>Listo</h3>
                      <p>OFRECEMOS SOLUCIONES A LA MEDIDA DE LA NECESIDAD DE NUESTROS CLIENTES</p>
                    </Carousel.Caption>
                  </Carousel.Item>
              )})
    }
    </Carousel>
  )
}
