import React, { useContext, useEffect, useState } from 'react'
import homeAdminContext from '../../context/homeAdmin/homeAdminContext';
import '../../styles/Home/aboutUs.css'
export const AboutUs = () => {
  const {imageUs} = useContext(homeAdminContext)
  const [ImageUs, setimageUs] = useState(imageUs);
  useEffect(() => {
    setimageUs(imageUs)
  }, [setimageUs, imageUs])
  return (
    <div className="aboutUs-container" id="nosotros">
      <div className="grid">
        <div className="image">
           {ImageUs!==null && ImageUs!==undefined && <img src={ImageUs.url} alt="US-section" />}
        </div>
        <div className="text">
          <h2>Nosotros</h2>
          <p>
          Somos una empresa con enfoque social que busca oficializar la labor doméstica, dirigida a la pequeña y mediana empresa así como grandes corporativos que no cuentan con un servicio de limpieza, nuestra propuesta se basa en ofrecer experiencia, calidad y un ambiente libre de suciedad para beneficio de todas las personas que conviven en las instalaciones.
          </p>
        </div>
      </div>
    </div>
  )
}
