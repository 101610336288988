import React, { useContext, useEffect, useState } from 'react'
import AdminContext from '../../context/admin/adminContext';
import homeAdminContext from '../../context/homeAdmin/homeAdminContext';
import '../../styles/Home/servicios.css'
export const Servicios = () => {
  const {imageServices} = useContext(homeAdminContext)
  const [ImageServices, setimageServices] = useState(null);
  useEffect(() => {
    setimageServices(imageServices)
  }, [setimageServices, imageServices, ImageServices])
  
  const {sortedServices, } = useContext(AdminContext)
  const [services, setservices] = useState(sortedServices)
  useEffect(() => {
    setservices(sortedServices)
  }, [setservices, sortedServices])
  return (
    ImageServices!==null && ImageServices!==undefined &&
    <div className='servicios-container' id="servicios"  style={{backgroundImage: `url(${ImageServices.url})` }}>
        <div className='title-container divider'>
            <span></span><span><h1>Servicios</h1></span><span></span>
        </div>
        <div className="cards">
          {
              (services!==null && services !==undefined) &&
                services.map((service) =>{
                  return (
          <div className="cards_item" key={service.id}>
            <div className="card">
              <div className="card_image"><img src={service.photo} /></div>
              <div className="card_content">
                <h2 className="card_title">{service.name}</h2>
                <p className="card_text">{service.description}</p>
              </div>
            </div>
          </div>)}
          )}
        </div>
    </div>
  )
}