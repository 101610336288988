import React, { useContext} from 'react'
import StrongContext from '../../context/strongConcrete/strongContext';
import { useForm } from '../../hooks/useForm';
import '../../styles/Home/contactForm.css'
import Swal from "sweetalert2";
export const ContactForm = () => {
  // funcion para enviar los datos del formulario de contactos.
  const initialState={correo:'', telefono:'', nombre:'', apellido:'', texto:''}
  let [formValues, handleInputChange, reset]=useForm(initialState);
  let {nombre, apellido, telefono, correo, texto}=formValues;
  const {sendMail} = useContext(StrongContext)
  const evaluarCampos=()=>{
		const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
	  if(correo.trim() ==='' || apellido.trim() ==='' || telefono.trim() ==='' || nombre.trim() ==='' || texto.trim() ===''){
      Swal.fire("Error", "Todos los campos son obligatorios", "error");
      return false
    }
	  if (!emailRegex.test(correo)) {
			Swal.fire("Error", "Ingrese un correo válido", "error");
	    return false;
	  }
		
		return true
	}
  
  const submitHandler =async(e) => {
    e.preventDefault();
    if(evaluarCampos()){
      await sendMail(
        {
          name: nombre,
          email: correo,
          message: texto,
          lastname: apellido,
          tel: telefono,
        }
      )
      reset({correo:'', telefono:'', nombre:'', apellido:'', texto:''});
    }
  };

  return (
    <div className="form">

        <h1 className="text-center">Preguntas</h1>
        <label className="form-label" >Para cotizaciones, preguntas, comentarios e inquietudes completa el formulario a continuación:</label>
        <form >
            <div >
            <label className="form-label" >Nombre</label>
                <input className="form-control" type="name" id="name" value={nombre} name="nombre" onChange={handleInputChange}>
                </input>
                <label className="form-label" >Apellido</label>
                <input className="form-control" type="lastName" id="lastName" value={apellido} name="apellido" onChange={handleInputChange}>
                </input>
                <label className="form-label" >Correo</label>
                <input className="form-control" type="email" id="email" required value={correo} name="correo" onChange={handleInputChange}>
                </input>
                <div className="invalid-feedback">
                    Por favor, ingresa tu correo
                </div>
                <label className="form-label">Teléfono</label>
                <input className="form-control" type="tel" id="phoneNumber" value={telefono} name="telefono" onChange={handleInputChange}>
                </input>
                <label className="form-label" >Déjanos un mensaje ...</label>
                <textarea  className="form-control" type="message" id="message" rows="4" value={texto} name="texto" onChange={handleInputChange}>
                </textarea >
            </div>
        
            <input className="btn btn-success w-100" type="submit" value="ENVIAR" onClick={submitHandler}>
            </input>
        </form>

    </div>
  )
}
