import React from 'react'
import { WelcomeCardUser } from './WelcomeCardUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import '../../styles/User/userDashboardCard.css'
export const Dashboard = () => {
  return (
    <>
        <div>
            <div className="row" style={{marginTop:"3em"}}>
                <div className="col-12 col-sm-12 col-md-5 col-lg-4" style={{marginBottom:"2em"}}>
                    <WelcomeCardUser />
                </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8" style={{marginBottom:"2em"}}>
                <div className='card-user'>
                    <div><FontAwesomeIcon icon={ faUser} className="card-user-icon" /></div>
                    <div>Aún no hay acciones para este usuario</div>
                </div>
              </div>
            </div>
        </div>
    
    </>
  )
}