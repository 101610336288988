import React, { useContext, useEffect} from 'react';
import AdminContext from '../../context/admin/adminContext';
import FirebaseContext from '../../context/firebase/firebaseContext';
import { useForm } from '../../hooks/useForm';
import Swal from "sweetalert2";
export const UserForm = () => {
    const {handleClose, adminState, editFirebaseUser} = useContext(AdminContext)
    const {Login, firebaseState}=useContext(FirebaseContext)
    const {user}=adminState;
    // manejo del formulario
    const initialState={name:user.name, email: user.email, rol: user.rol}
    let [formValues, handleInputChange, reset]=useForm(initialState);
    let {name, email, rol}=formValues;
    useEffect(() => {
      return
    }, [Login])
    const closeModal=async(e)=>{
        e.preventDefault();
        await handleClose();
    }
    const handleSubmit=async(e)=>{
        e.preventDefault();
        if(name.trim()===''|| email.trim()===''){
          Swal.fire("Error", "Todos los campos deben llenarse", "error");
          return
        }
        await editFirebaseUser({name, email, rol, id:user.id});
        if(firebaseState.user.email===user.email){
            Login({name, email, rol, date:user.date})
        }
        reset({name:'', email: '', rol: ''});
        handleClose();
        console.log(name, email, rol)
    }
  return (
    <>
    <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
            <input type="text" className="form-control" id="inputName" name="name" placeholder="Nombre" value={name} onChange={handleInputChange}/>
        </div>
        <div className="col-12">
            <input type="email" className="form-control" id="inputEmail4" name="email" placeholder="E-mail" value={email} onChange={handleInputChange}/>
        </div>
        <div className="col-12">
            <label for="inputRol" className="form-label" >Rol: </label>
            <select id="inputRol" className="form-select" name="rol" onChange={handleInputChange} defaultValue={rol} >
              
              <option value='user'>user</option>
              <option value='admin'>admin</option>
            </select>
      </div>
      <div className="col-12" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <button type="submit" className="btn btn-primary btnAccept">Editar</button>
        
        <button className="btn btn-primary btnCancel" onClick={closeModal}>
            Cancelar
        </button>
      </div>
    </form>
    
    </>
  )
}
