import { types } from "../../types";

const adminReducer=(state, action)=>{
    switch (action.type) {
        case types.changeScreen:
            return {
                ...state,
                activeScreen:action.payload
            }
        case types.modalView:
            return {
                ...state,
                modalScreen:action.payload
            }
        case types.modalState:
            return {
                ...state,
                show:action.payload
            }
        case types.userEdit:
            return {
                ...state, 
                user:action.payload
            }
        case types.categoryEdit:
                return {
                    ...state, 
                    category:action.payload
            }
        case types.projectEdit:
                return {
                    ...state, 
                    project:action.payload
            }
        case types.serviceEdit:
                return {
                    ...state, 
                    service:action.payload
            }
        default:
            return state;
    }

}

export default adminReducer;