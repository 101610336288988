import React from 'react'
import { Dashboard } from './Dashboard'
import '../../styles/Admin/tablesAndPagination.css'
import { HeaderUser } from './HeaderUser'
export const BodyUser = () => {
  return (
    <>
    <label for="check-menu" className="menu-toogle"></label>
    <div className="cuerpo">
        <HeaderUser/>
        <Dashboard />
    </div>
    
    </>
  )
}
