import { types } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case types.login:
            return {
                ...state,
                user: action.payload.user,
                rol:action.payload.rol,
                isLogged:true
            }
        case types.logout:
            return {
                ...state,
                user: '',
                rol:'',
                isLogged:false
                }
        case types.loggedOut:
            return {
                ...state,
                isLogged:false,
                user: '',
                rol:''
            }
        default:
            return state;
    }
}

