import React from 'react'
import '../../styles/Home/contact.css'
import { ContactForm } from './ContactForm'
import { Footer } from './Footer'
export const Contact = () => {
  return (
   <>
    <div className="contact-container" id="contacto">
        <div className='title-container divider'>
            <span></span><span><h1>Contacto</h1></span><span></span>
        </div>
        <div className="mapa">
          <iframe title="mapa_google" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d898.3167800246069!2d-80.19228017079179!3d25.761739106788706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ef8b1ca19e3e839!2zMjXCsDQ1JzQyLjMiTiA4MMKwMTEnMzAuMiJX!5e0!3m2!1ses-419!2shn!4v1659416514855!5m2!1ses-419!2shn" width="100%" height="100%"   loading="lazy" ></iframe>
         </div>
        <div className='body-container'>
          <div className='contact-form'>
            <ContactForm />
          </div>
          <div className='additional-info'>
            <h1>
              Oficina principal
            </h1>
            <div className="bar">
              <div className="filledbar"></div>
            </div>
            <div className="datos">Tegucigalpa,Boulevard Morazán</div>
            <div className="datos">example.info@gmail.com</div>
            <div className="datos">Teléfono: </div>
            <div className="datosPresupuesto">
            Recibe un presupuesto: <div></div>
            </div>
          </div>
        </div>
        <Footer />
    </div>
   
   </>
  )
}
