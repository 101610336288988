import React, { useContext, useState } from 'react';
import AdminContext from '../../context/admin/adminContext';
import { collection, addDoc, doc, updateDoc} from "firebase/firestore";
import { useForm } from '../../hooks/useForm';
import { db, storage } from '../../firebase/firebase-config';
import { ref, uploadBytesResumable, getDownloadURL, } from 'firebase/storage'
import { v4 } from 'uuid'
import Swal from "sweetalert2";
import { myDate } from '../../helpers/Date';

export const ProjectForm = () => {
    const {handleClose, adminState, editFirebaseProject, sortedCategories} = useContext(AdminContext)
    const {modalScreen, project}=adminState;
    const [imageUpload, setimageUpload] = useState(null)
    const [categories, ] = useState(sortedCategories)
    // manejo del formulario
    let initialState={name:'',description:'', category: categories[0].category}
    if(modalScreen==='editarProyecto'){
        initialState={name:project.name, category: project.category, photo:project.photo ,description:project.description}
    }
    let [formValues, handleInputChange, reset]=useForm(initialState);
    let {name, category, description}=formValues;
    
    const closeModal=async(e)=>{
        e.preventDefault();
        await handleClose();
    }
    const resetAndClose=()=>{
        setimageUpload(null)
        reset({name:'',description:'', category: categories[0].category});
        handleClose()
    }
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        //Comprobar que no se esté agregando imagen al editar
        if(name.trim() ===''){
            Swal.fire("Error", "Todos los campos son obligatorios", "error");
            return
        }
        if(modalScreen==='editarProyecto' && imageUpload ===null){
            editFirebaseProject({name, category, description, photo:project.photo, id:project.id});
            resetAndClose();
        //Si se está creando un nuevo registro
        }else if(modalScreen==='agregarProyecto'){
            if(imageUpload===null){
                Swal.fire("Error", "Debe agregar una imagen al proyecto", "error");
                return
            }
            const date=myDate().split(" ")[0].split("/").reverse().join("/");
            //creando el registro del proyecto
            addDoc(collection(db, "projects"), {
                name,
                category,
                description,
                date
            }).then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `projects/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, 'projects', docum.id);
                                    updateDoc(docuRef, {photo:URL})
                                    .then(() => {
                                        Swal.fire("Proyecto guardado", "Proyecto guardado con éxito", "success");
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    resetAndClose();
                                });
                        }
                    )
    
                };
            });    
        //si se está editando un registro y guardando una imagen
        }else{
            //obteniendo la referencia al registro
            const docuRef =doc(db, 'projects', project.id);
            updateDoc(docuRef, {name, category, description})
            .then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `projects/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, 'projects', project.id);
                                    updateDoc(docuRef, {photo:URL})
                                    .then(() => {
                                        Swal.fire("Edición de proyecto", "Proyecto actualizado con éxito", "success");
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    resetAndClose();
                                });
                        }
                    )
    
                };
            });    
        }
    }
  return (
    <>
    <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
            <input type="text" className="form-control"name="name" placeholder="Proyecto" value={name} onChange={handleInputChange}/>
        </div>
        <div className="col-12">
            <label for="inputRol" className="form-label" >Categoría: </label>
            <select id="inputRol" className="form-select" name="category" onChange={handleInputChange} defaultValue={modalScreen==='editarProyecto'? project.category : categories[0].category} >
                {categories.map((category) =><option value={category.category} key={category.id}>{category.category}</option>)}
            </select>
        </div>
         <div className="col-12">
            <input type="text" className="form-control"name="description" placeholder="Descripción del proyecto" value={description} onChange={handleInputChange}/>
        </div>
        {
        modalScreen==='editarProyecto' &&
        (
        <div className="col-12">
            <label className="form-label">Imagen actual: </label>
            <div  style={{backgroundImage: `url(${project.photo})` }} className='image-form-container'>
            
            </div>
        </div>
        )
        }
        <div className="col-12">
            <label className="form-label">Imagen (tamaño recomendado 300x340px): </label>
            <input type="file" className="form-control" name="photo" onChange={(event)=>{setimageUpload(event.target.files[0])}}/>
        </div>
        
        <div className="col-12" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <button type="submit" className="btn btn-primary btnAccept">{modalScreen==='editarProyecto' ? 'Editar' : 'Agregar'}</button>
            
            <button className="btn btn-primary btnCancel" onClick={closeModal}>
                Cancelar
            </button>
        </div>
    </form>
    
    </>
  )
}
