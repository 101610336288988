export const types={
    login: '[auth] Login',
    logout: '[auth] Logout',
    loggedOut:'[auth] loggedOut',
    changeScreen:'[admin] changeScreen',
    modalView:'[admin] modalView',
    modalState:'[admin] modalState',
    userEdit:'[admin] userEdit',
    userEditFirebase:'[admin] userEditFirebase',
    categoryEdit:'[admin] categoryEdit',
    projectEdit:'[admin] projectEdit',
    serviceEdit:'[admin] serviceEdit',
    changeHomeScreen:'[adminHome] changeHomeScreen',
}