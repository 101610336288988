import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter} from "react-router-dom";
import FirebaseContext from '../context/firebase/firebaseContext';
import { db } from '../firebase/firebase-config';
import { AdminView } from '../views/AdminView';
import { UserView } from '../views/UserView';
import { AuthRouter } from './AuthRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Spinner from 'react-bootstrap/Spinner';
import { Home } from '../views/Home';
export const AppRouter = () => {
  const {Login, firebaseState, setLoggedOut} = useContext(FirebaseContext)
  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async(user)=> {
      if (user) {
        const userRef = doc(db, 'users', `${user.uid}`);
        const docSnap = await getDoc(userRef);
        const userLogged=docSnap.data();
        Login(userLogged);
        setIsLoggedIn(true);
      }else{
        setIsLoggedIn(false);
        await setLoggedOut();
      }
      setChecking(false);
    
  })
  }, [setChecking, setIsLoggedIn, firebaseState.rol, firebaseState.user.name, firebaseState.user.rol,firebaseState.isLogged]);
  if(checking){
    return (
      <div className='spinner-container'>
         <div className='text-center align-middle spinner'>
          <Spinner animation="border" variant="warning" />
        </div>
      </div>
     
      )
  
  }else{
    return (
        <BrowserRouter>   
              <Routes>
                <Route exact path="/" element={
                    <PrivateRoute isAuthenticated={isLoggedIn}>
                      {
                        firebaseState.rol==='user' ? <UserView /> : <AdminView />
                      }
                      
                    </PrivateRoute>
                  }/>
                 <Route path="/home" element={
                    <Home />
                  }/>
                  <Route path="/*" element={
                    <PublicRoute isAuthenticated={isLoggedIn}>
                      <AuthRouter />
                    </PublicRoute>
                  }/>
              </Routes>
      </BrowserRouter>
       )
  }
}
      
 
 
