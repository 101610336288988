/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext} from 'react'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons'
import FirebaseContext from '../../context/firebase/firebaseContext'
export const HeaderUser = () => {
    const {startLogOut, firebaseState, logOut} = useContext(FirebaseContext);
    const navigate=useNavigate();
  return (
    <div className='HeaderAdmin'>
        <div><FontAwesomeIcon className="fontAwesomeIcon" icon={faHome} onClick={()=>{navigate("/home", { replace: true });}}/>/Dashboard</div>
        <div>
            <span><FontAwesomeIcon className="fontAwesomeIcon" icon={ faBell} /></span>
            <span> <FontAwesomeIcon className="fontAwesomeIcon" icon={ faUserCircle} />{firebaseState.user.name}</span>
            <button onClick={async()=>{
              startLogOut();
              await logOut();
              }} style={{backgroundColor:"transparent", border:"none", fontSize:"12px", color:"#fff", fontWeight:"bold"}}><a >Cerrar sesión</a></button>
            
       </div>
    </div>
  )
}
