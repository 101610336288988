import React from 'react'
import { WelcomeCardAdmin } from './WelcomeCardAdmin'
import { AdminDashboardCards } from './DashboardCards'
import { Graphic } from './Graphic'
export const Dashboard = () => {
  return (
    <>
        <AdminDashboardCards />
        <div>
            <div className="row" style={{marginTop:"3em"}}>
                <div className="col-12 col-sm-12 col-md-5 col-lg-4" style={{marginBottom:"2em"}}>
                    <WelcomeCardAdmin />
                </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8" style={{marginBottom:"2em"}}>
                <img src="https://img.freepik.com/vector-premium/grafico-lineas-grafico-barras-que-muestra-precio-mercado-valores-o-beneficio-inversion-empresarial-financiera_120819-648.jpg"
                alt="grafico"
                style={{width:"100%", heigth:"auto"}}
                />
              </div>
            </div>
        </div>
    
    </>
  )
}
