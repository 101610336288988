import React, { useReducer } from 'react';
import strongReducer from './strongReducer';
import StrongContext from './strongContext'
import Swal from "sweetalert2";
const StrongState=(props)=>{
    
    const apiURL=process.env.REACT_APP_APIURL
    
    const initialState={
    }
    
    const [state, ]=useReducer(strongReducer, initialState)
    
    const sendMail=async({name, lastname, email, tel, message })=>{
        let response = await fetch(apiURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({name, lastname, email, tel, message }),
          });
        if(response.statusText==="OK"){
            Swal.fire("Success", "Se ha enviado tu mensaje, nos comunicaremos contigo lo más pronto posible.", "success");
        }
    }
   
    return (
        <StrongContext.Provider
            value={{
                strongState:state, 
                sendMail
            }}
        
        >
            {props.children}
        </StrongContext.Provider>
    
    )
}

export default StrongState;