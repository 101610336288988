import React, { useContext} from 'react'
import FirebaseContext from '../../context/firebase/firebaseContext'
import '../../styles/Admin/welcomeAdminCard.css'
export const WelcomeCardUser = () => {
  const {firebaseState} = useContext(FirebaseContext);
  
  return (
    <div className="welcomeAdmin-container" 
        style={{backgroundImage: `url("https://petkeen.com/wp-content/uploads/2021/01/Multi-color-Siamese-fighting-fish-betta_panpilai-paipa_shutterstock.jpg")` }}>
        <div>Bienvenido,</div>
        <div className='adminCardName'>{firebaseState.user.name}</div>
        <div>Nos alegra tenerte de vuelta</div>
    </div>
  )
  
}