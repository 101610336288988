/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import '../../styles/Home/footer.css'

export const Footer = () => {
  return (
    <div className='footer-container'>
       <div className="footer-top">
            <ul>
              <li><a href="/">Contact</a></li>
               <li><a href="/">About</a></li>
            </ul>   
        </div> 
        <div className="footer-bottom">
            <span>
               © 2022, Listo   
            </span>
            <span className="social-media">
                <a href="#" className="fa fa-facebook"></a>
                <a href="#" className="fa fa-twitter"></a>
                <a href="#" className="fa fa-instagram"></a>
            </span>
        </div>
    </div>
  )
}
