import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import homeAdminContext from '../../../context/homeAdmin/homeAdminContext';
import { collection, addDoc, doc, updateDoc} from "firebase/firestore";
import { db, storage } from '../../../firebase/firebase-config';
import { ref, uploadBytesResumable, getDownloadURL, } from 'firebase/storage'
import { v4 } from 'uuid'
import Swal from "sweetalert2";
import { myDate } from '../../../helpers/Date';
import '../../../styles/Admin/modal.css'
export const ModalImages = () => {
    const {show, handleClose,  homeAdminState, imageServices, imageUs } = useContext(homeAdminContext)
    const {modalScreen}=homeAdminState;
    const [imageUpload, setimageUpload] = useState(null)
    const [imageToEdit, setImageToEdit]=useState('')
    useEffect(() => {
        if(modalScreen==='servicesEditar'){
            setImageToEdit(imageServices)
        }else{
            setImageToEdit(imageUs)
        }
    }, [setImageToEdit, imageServices, imageUs, modalScreen])
    
    const handleSubmit=(e)=>{
        e.preventDefault()
        if(imageUpload===null){
            Swal.fire("Error", "Seleccione una imagen", "error");
            return
        }
        if (modalScreen==="servicesEditar" || modalScreen==="usEditar"){
            //obteniendo la referencia al registro
            let collection=""
            if(modalScreen==="servicesEditar"){
                collection="servicesImage";
            }else{
                collection="usImage";
            }
            const docuRef =doc(db, collection, imageToEdit.id);
            updateDoc(docuRef, {url:''})
            .then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `${collection}/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, collection, imageToEdit.id);
                                    updateDoc(docuRef, {url:URL})
                                    .then(() => {
                                        Swal.fire("Edición de imagen", "Imagen actualizada con éxito", "success");
                                        
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    setimageUpload(null)
                                    handleClose()
                                });
                        }
                    )
    
                };
            });  
            return
        }
            const date=myDate().split(" ")[0].split("/").reverse().join("/");
            //creando el registro 
            addDoc(collection(db, modalScreen), {
                url:'',
                date
            }).then(docum => {
                //subiendo la imagen
                if (imageUpload) {
                    const storageRef = ref(storage, `${modalScreen}/${imageUpload.name+v4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, imageUpload, "data_url");
                    uploadTask.on('state_changed', null,
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            //actualizando el registro una vez que se haya guardado la imagen
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((URL) => {
                                    const docuRef =doc(db, modalScreen, docum.id);
                                    updateDoc(docuRef, {url:URL})
                                    .then(() => {
                                        
                                        Swal.fire("Imagen subida", "Se ha guardado la imagen con éxito", "success");
                                        
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        Swal.fire("Error", "No se ha podido completar la acción", "error");
                                    })
                                    setimageUpload(null)
                                    handleClose()
                                });
                        }
                    )
    
                };
            });    
        
        
    }
  return (
    <>
    <Modal show={show} onHide={handleClose} className="modal" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Agregar Imagen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form className="row g-3" >
        
                <div className="col-12">
                    <label for="photo" className="form-label" >Imagen: </label>
                    <input type="file" className="form-control" name="photo" onChange={(event)=>{setimageUpload(event.target.files[0])}}/>
                </div>
                
                <button type="submit" className="btn btn-primary btnAccept" onSubmit={handleSubmit}>Subir imagen</button>
            </form>
            <div className="row g-3 mt-2" >
                    <button className="btn btn-primary btnCancel" onClick={handleClose}>
                        Cancelar
                    </button>
            </div>
        </Modal.Body>
      </Modal>
    
    </>
  )
}
