import React, { useReducer, useEffect, useState } from 'react';
import homeAdminReducer from './homeAdminReducer';
import homeAdminContext from './homeAdminContext'
import { onSnapshot, collection, doc, deleteDoc} from 'firebase/firestore';
import {db} from '../../firebase/firebase-config';
import { types } from '../../types';
import Swal from "sweetalert2";
const HomeAdminState=(props)=>{
    
    const initialState={
        modalScreen:'',
    }
    const [state, dispatch]=useReducer(homeAdminReducer, initialState)
   
    
    /*****Modal ************/
    const [show, setShow] = useState(state.show);
    const handleClose = async() =>{
        setShow(false);
        dispatch({
            type:types.modalState,
            payload:false
        })
    } 
    const handleShow = () =>{
        setShow(true);
        dispatch({
            type:types.modalState,
            payload:true
        })
    } 
    
    const changeScreen=(screenSelected)=>{
        dispatch({
            type:types.changeHomeScreen,
            payload:screenSelected
        })
    }
    
    //////////////////////////////*CAROUSEL*//////////////////////////
     /****Obtención de imagenes del carousel */
     const [carouselImages, setcarouselImages] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'carousel')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setcarouselImages(data)
             })
       
     },[])
     /**************Ordenar imagenes */
    const sortedCarousel = carouselImages.sort((a,b)=>(a.url< b.url? -1 : 1));
   
    const deleteFirebaseCarouselImage=(id)=>{
        Swal.fire({
            title: '¿Estas seguro de que deseas eliminar esta imagen? Esta acción no se puede revertir',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then(async(result) => {
            if (result.isConfirmed) {
                const docuRef =doc(db, 'carousel', id);
                await deleteDoc(docuRef)
                .then(() => {
                    Swal.fire("Eliminar Imagen", "Se eliminó la imagen", "success");
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error", "No se ha podido completar la acción", "error");
                })
            }
          }) 
    }   
//////////////////////////////*SERVICIOS*//////////////////////////
     /****Obtención de imagen de la sección de servicio */
     const [servicesImage, setservicesImage] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'servicesImage')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setservicesImage(data)
             })
       
     },[])
     const imageServices=servicesImage[0];
 //////////////////////////////*NOSOTROS*//////////////////////////
     /****Obtención de imagen de la sección de nosotros */
     const [usImage, setusImage] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'usImage')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setusImage(data)
             })
       
     },[])
     const imageUs=usImage[0];
    return (
        <homeAdminContext.Provider
            value={{
                homeAdminState:state,
                sortedCarousel,
                deleteFirebaseCarouselImage,
                show, 
                setShow,
                handleClose,
                handleShow,
                changeScreen,
                imageServices,
                imageUs
            }}
        >
            {props.children}
        </homeAdminContext.Provider>
    
    )
}

export default HomeAdminState;