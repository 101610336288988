import React from 'react'
import { Cards } from './homeScreenComponents/Cards'

export const HomeScreen = () => {
  return (
    <div>
      <Cards />
    </div>
  )
}
