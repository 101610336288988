import React, { useReducer, useEffect, useState } from 'react';
import AdminContext from './adminContext'
import adminReducer from './adminReducer'
import { onSnapshot, collection, doc, updateDoc, deleteDoc, addDoc} from 'firebase/firestore';
import {db} from '../../firebase/firebase-config';
import { types } from '../../types';
import Swal from "sweetalert2";
import { myDate } from '../../helpers/Date.js';
const AdminState=(props)=>{
    
    const initialState={
        activeScreen:'dashboard',
        modalScreen:'',
        show:false,
        user:'',
        category:'',
        project:'',
        service:''
    }
    const [state, dispatch]=useReducer(adminReducer , initialState)
   
    
    /*****Modal ************/
    const [show, setShow] = useState(state.show);
    const handleClose = async() =>{
        setShow(false);
        dispatch({
            type:types.modalState,
            payload:false
        })
    } 
    const handleShow = () =>{
        setShow(true);
        dispatch({
            type:types.modalState,
            payload:true
        })
    } 
    
    const changeScreen=(screenSelected)=>{
        dispatch({
            type:types.changeScreen,
            payload:screenSelected
        })
    }
    
    const changeModalView=(modalView)=>{
        dispatch({
            type:types.modalView,
            payload:modalView
        })
    }
    
    
    ///////////////*USER*//////////////////////////////////////
    /****Obtención de usuarios */
    const [user, setusers] = useState([]);
    useEffect(() => {
       
      const docRef = collection(db , 'users')
        onSnapshot(docRef,(snapshot)=>{
            const data = [];
                snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
              })
              setusers(data)
          
            })
      
    },[])
    /**************Ordenar usuarios */
    const sortedusers = user.sort((a,b)=>(a.name < b.name ? -1 : 1));
     
    const setUserEdit=(user)=>{
        dispatch({
            type:types.userEdit,
            payload:user
        })
    }
    const editFirebaseUser=async(user)=>{
        const docuRef =doc(db, 'users', user.id);
        await updateDoc(docuRef, { name: user.name, email: user.email, rol: user.rol })
        .then(() => {
            Swal.fire("Edición de usuario", "Usuario actualizado con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }
    const deleteFirebaseUser=(id)=>{
        Swal.fire({
            title: '¿Estas seguro de que deseas eliminar este usuario? Esta acción no se puede revertir',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then(async(result) => {
            if (result.isConfirmed) {
                const docuRef =doc(db, 'users', id);
                await deleteDoc(docuRef)
                .then(() => {
                    Swal.fire("Eliminar Usuario", "Usuario eliminado exitosamente", "success");
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error", "No se ha podido completar la acción", "error");
                })
            }
          }) 
    }
    
    ///////////////*CATEGORY*//////////////////////////////////
     /****Obtención de categorias */
     const [categories, setcategories] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'categories')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setcategories(data)
             })
       
     },[])
     /**************Ordenar categorias */
    const sortedCategories = categories.sort((a,b)=>(a.category< b.category? -1 : 1));
    
    const setCategoryEdit=(category)=>{
        dispatch({
            type:types.categoryEdit,
            payload:category
        })
    }
    const editFirebaseCategory=async(category)=>{
        const docuRef =doc(db, 'categories', category.id);
        await updateDoc(docuRef, { category: category.category })
        .then(() => {
            Swal.fire("Edición de categoría", "Categoría actualizada con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }
    const addFirebaseCategory=async(category)=>{
        const date=myDate().split(" ")[0].split("/").reverse().join("/");
        const data={
            category:category.category,
            date
        }
        const dbRef =collection(db, 'categories');
        addDoc(dbRef, data)
        .then((docRef) => {
            Swal.fire("Agregar Categoría", "Categoría agregada con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }
    const deleteFirebaseCategory=(id)=>{
        Swal.fire({
            title: '¿Estas seguro de que deseas eliminar esta categoría? Esta acción no se puede revertir',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then(async(result) => {
            if (result.isConfirmed) {
                const docuRef =doc(db, 'categories', id);
                await deleteDoc(docuRef)
                .then(() => {
                    Swal.fire("Eliminar Categoría", "Categoría eliminada exitosamente", "success");
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error", "No se ha podido completar la acción", "error");
                })
            }
          }) 
    }
    
    //////////////////////////////*PROJECT*//////////////////////////
     /****Obtención de proyectos */
     const [projects, setprojects] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'projects')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setprojects(data)
             })
       
     },[])
     /**************Ordenar proyectos */
    const sortedProjects = projects.sort((a,b)=>(a.name< b.name? -1 : 1));
    
    const setProjectEdit=(project)=>{
        dispatch({
            type:types.projectEdit,
            payload:project
        })
    }
    const editFirebaseProject=async(project)=>{
        const docuRef =doc(db, 'projects', project.id);
        await updateDoc(docuRef, {name: project.name, category:project.category, description: project.description})
        .then(() => {
            Swal.fire("Edición de proyecto", "Proyecto actualizado con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }
    
    /* ESTA FUNCIÓN PASA A UTILIZARSE DIRECTAMENTE EN EL COMPONENTE
    const addFirebaseProject=async(project)=>{
        const date=myDate().split(" ")[0].split("/").reverse().join("/");
        const data={
            ...project,
            date
        }
        const dbRef =collection(db, 'projects');
        addDoc(dbRef, data)
        .then((docRef) => {
            Swal.fire("Agregar Proyecto", "Proyecto agregado con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }*/
    const deleteFirebaseProject=(id)=>{
        Swal.fire({
            title: '¿Estas seguro de que deseas eliminar este proyecto? Esta acción no se puede revertir',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then(async(result) => {
            if (result.isConfirmed) {
                const docuRef =doc(db, 'projects', id);
                await deleteDoc(docuRef)
                .then(() => {
                    Swal.fire("Eliminar Proyecto", "Proyecto eliminado exitosamente", "success");
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error", "No se ha podido completar la acción", "error");
                })
            }
          }) 
    }
    //////////////////////////////*SERVICIOS*//////////////////////////
     /****Obtención de servicios */
     const [services, setservices] = useState([]);
     useEffect(() => {
        
       const dbRef = collection(db , 'services')
         onSnapshot(dbRef,(snapshot)=>{
             const data = [];
                 snapshot.forEach((doc) => {data.push({...doc.data(),id: doc.id,})
               })
               setservices(data)
             })
       
     },[])
     /**************Ordenar proyectos */
    const sortedServices = services.sort((a,b)=>(a.name< b.name? -1 : 1));
    
    const setServiceEdit=(service)=>{
        dispatch({
            type:types.serviceEdit,
            payload:service
        })
    }
    const editFirebaseService=async(service)=>{
        const docuRef =doc(db, 'services', service.id);
        await updateDoc(docuRef, {name: service.name, description:service.description})
        .then(() => {
            Swal.fire("Edición de Servicio", "Servicio actualizado con éxito", "success");
        })
        .catch(error => {
            console.log(error)
            Swal.fire("Error", "No se ha podido completar la acción", "error");
        })
          
    }
    
    const deleteFirebaseService=(id)=>{
        Swal.fire({
            title: '¿Estas seguro de que deseas eliminar este servicio? Esta acción no se puede revertir',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then(async(result) => {
            if (result.isConfirmed) {
                const docuRef =doc(db, 'services', id);
                await deleteDoc(docuRef)
                .then(() => {
                    Swal.fire("Eliminar Servicio", "Servicio eliminado exitosamente", "success");
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire("Error", "No se ha podido completar la acción", "error");
                })
            }
          }) 
    }   
    
    return (
        <AdminContext.Provider
            value={{
                adminState:state,
                changeScreen, 
                sortedusers,
                changeModalView,
                show,
                setShow,
                handleClose,
                handleShow,
                setUserEdit,
                editFirebaseUser,
                deleteFirebaseUser,
                setCategoryEdit,
                editFirebaseCategory,
                addFirebaseCategory,
                deleteFirebaseCategory,
                sortedCategories,
                setProjectEdit,
                editFirebaseProject,
                //addFirebaseProject,
                deleteFirebaseProject,
                sortedProjects,
                setServiceEdit,
                editFirebaseService,
                deleteFirebaseService,
                sortedServices
            }}
        
        >
            {props.children}
        </AdminContext.Provider>
    
    )
}

export default AdminState;