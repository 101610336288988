import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
export const LoginRegisterNavbar = () => {
  const navigate=useNavigate();
  return (
    <nav className="navbar">
        <div className="navbar-container container">
            <input type="checkbox" name="" id="" />
            <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </div>
            <ul className="menu-items">
                <li style={{marginRight:"-4em !important"}} onClick={()=>{navigate("/home", { replace: true }) }}><FontAwesomeIcon icon={ faArrowLeftLong } />Atrás</li>
            </ul>
            
            <div className="logo" onClick={()=>{navigate("/home", { replace: true }) }}>
              <h1>
              <img src="/assets/Logo transparencia-02.png" style={{width:"2.5em", heigh:"2.5em"}} alt="logo"/>
             </h1>
            </div>
        </div>
    </nav>
  )
}
