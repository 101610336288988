/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import '../styles/Login/login.css'
import { Link} from 'react-router-dom'
import { LoginRegisterNavbar } from '../components/ui/LoginRegisterNavbar'
import FirebaseContext from '../context/firebase/firebaseContext'
import { useForm } from '../hooks/useForm';
import Swal from "sweetalert2";
export const RegisterScreen = () => {
	const {register, googlelogin} = useContext(FirebaseContext)
	const [formValues, handleInputChange]=useForm({name:'', lastname:'', email:'', password:'', confirmpassword:''});
	const {name, lastname, email, password, confirmpassword}=formValues;
	const evaluarCampos=()=>{
		const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
	    if(name.trim()===''||lastname===''||email===''||password===''||confirmpassword===''){
			Swal.fire("Error", "Todos los campos son obligatorios", "error");
	        return false;
	    }
	    if (!emailRegex.test(email)) {
			Swal.fire("Error", "Ingrese un correo válido", "error");
	        return false;
	    }
		if(password !== confirmpassword){
			Swal.fire("Error", "Las contraseñas no coinciden", "error");
			return false;
		} 
		if( password.length < 8){
			Swal.fire("Error", "La contraseña debe contener al menos 8 caracteres", "error");
			return false;
		}
		if(!password.match(/[A-z]/)){
			Swal.fire("Error", "La contraseña debe contener letras", "error");
			return false;
		}
		if(!password.match(/[A-Z]/)){
			Swal.fire("Error", "La contraseña debe contener al menos 1 letra mayúscula", "error");
			return false;
		}
		if(!password.match(/\d/)){
			Swal.fire("Error", "La contraseña debe contener números", "error");
			return false;
		}
		return true
	}
	const handleSubmit=async(e)=>{
		e.preventDefault();
		if(evaluarCampos()){
			await register({name, lastname, email, password})
		}
	}
  return (
  <>
    <LoginRegisterNavbar />
    <div className="img login-screen">
    	<section className="section">
    		<div className="container">
    			<div className="row justify-content-center">
    				<div className="col-md-6 text-center mb-5">
    					<h2 className="heading-section">Registro</h2>
    				</div>
    			</div>
    			<div className="row justify-content-center">
    				<div className="col-md-6 col-lg-4">
    					<div className="login-wrap p-0">
    		      	       <Link to="/login" ><h3 className="mb-4 text-center">¿Ya tienes una cuenta?</h3></Link>
    		      	       <form action="#" className="signin-form" onSubmit={handleSubmit}>
                                <div className="form-group">
    		      			        <input type="text" className="form-control" name="name" value={name} onChange={handleInputChange} placeholder="Nombre" required />
    		      		        </div>
                                <div className="form-group">
    		      			         <input type="text" className="form-control" name="lastname" value={lastname} onChange={handleInputChange} placeholder="apellido" required />
    		      		        </div>
    		      	            <div className="form-group">
    		      			        <input type="text" className="form-control" name="email" value={email} onChange={handleInputChange} placeholder="correo" required />
    		      		        </div>
			    	            <div className="form-group">
			    	              <input id="password-field" type="password" name="password" value={password} onChange={handleInputChange} className="form-control" placeholder="Contraseña" required />
			    	            </div>
			    	            <div className="form-group">
			    	              <input id="passwordConfirm-field" type="password" name="confirmpassword" value={confirmpassword} onChange={handleInputChange} className="form-control" placeholder="Confirmar contraseña" required />
			    	            </div>
			    	            <div className="form-group">
			    	            	<button type="submit" className="form-control btn btn-primary submit px-3">Registrarse</button>
			    	            </div>
			    	        </form>
			    	        <p className="w-100 text-center">&mdash; O registrarse con &mdash;</p>
    	                    <div className="social d-flex text-center">
    	          	            <a href="#" className="px-2 py-2 mr-md-1 rounded fa fa-google" onClick={()=>googlelogin()}> Google</a>
    	          	        </div>
    		            </div>
    				</div>
    			</div>
    		</div>
    	</section>
	</div>
	</>)
}
