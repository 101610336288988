/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
import { ActionsModal } from './ActionsModal'
export const UsersScreen = () => {
  const {
    sortedusers, 
    handleShow, 
    changeModalView, 
    setUserEdit,
    deleteFirebaseUser
    } = useContext(AdminContext)
  const [users, setusers] = useState(sortedusers)
  useEffect(() => {
    setusers(sortedusers);;
  }, [setusers, sortedusers])
  
  /***Modal *****/
  const actionModal=async(user)=>{
    await setUserEdit(user);
    await changeModalView('editarUsuario');
    await handleShow();
  } 
    
    
    
  return (
  <>
    <div className='usersScreen-container'>
        <div className="table-admin">
            <div className="row header-admin">
                <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-2 col-xl-3">
                  Nombre
                </div>
                <div className="col-4 col-sm-4 col-xs-4 col-md-4 col-lg-3 col-xl-4">
                  Correo
                </div>
                <div className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1 col-xl-1">
                  Rol
                </div>
                <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-3 col-xl-2">
                  Fecha de creación
                </div>
                <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-3 col-xl-2">
                  Acciones
                </div>
          </div>
           
            {
              (users!==null) &&
              users.map((user) =>{
                return (
                <>
                  <hr style={{backgroundColor:"#ccc"}}/>
                  <div className="row admin-row" key={user.id}>
                    <div className="col-3 col-sm-3 col-xs-3 col-md-3 col-lg-2 col-xl-3">
                      {user.name}
                    </div>
                    <div className="col-4 col-sm-4 col-xs-4 col-md-4 col-lg-3 col-xl-4">
                      {user.email}
                    </div>
                    <div className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1 col-xl-1">
                      {user.rol}
                    </div>
                    <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-3 col-xl-2">
                      {user.date}
                    </div>
                    <div className="col-2 col-sm-2 col-xs-2 col-md-2 col-lg-3 col-xl-2">
                      <FontAwesomeIcon className="EditButton" icon={ faEdit} onClick={()=>actionModal(user)}/>
                      <FontAwesomeIcon className="DeleteButton" icon={ faTrash} onClick={()=>deleteFirebaseUser(user.id)}/>
                    </div>
                  </div>
                </>
                )
              
              }
              )
            }
        </div>
        <div className='pagination-admin-container'>
            <nav aria-label="Page navigation ">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
        </div>
    </div>
    
    
    
    <ActionsModal />
  </>
    
  )
}
