import React, { useState } from 'react'
import '../../styles/Admin/lateralMenu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
export const LateralMenu = () => {
  const [active, setactive] = useState('home')
  const navigate=useNavigate();
  return (
    <>
    <input type="checkbox" id="check-menu"/>
    <label htmlFor="check-menu" className="pantalla-CerrarMenu"></label>
    <nav id="nav">
    	<label htmlFor="check-menu" className="menu-toogle"></label>
    	<div onClick={()=>{navigate("/home", { replace: true }) }} className="home">
    	 <div className="titulo">
            <div className="logo">
                <h1>
                <img src="/assets/Logo transparencia-02.png" style={{width:"2.5em", heigh:"2.5em"}} alt="logo"/>
                </h1>
              </div>
      	 </div>
    	 </div>
    	 <div className='separator'></div>
    	<ul className="MenuNav">
    		<li onClick={()=>{setactive("home"); navigate("/home", { replace: true }) }} className={`${active==="home"? "selectedItem": ''}`}> <FontAwesomeIcon className="fontAwesomeIcon" icon={faHome} /><div>Home</div></li>
    	</ul>
    
    </nav>
   
    </>
  )
}
