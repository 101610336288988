/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrash, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
import { ActionsModal } from './ActionsModal'
export const CategoriesScreen = () => {
  const {
    sortedCategories, 
    handleShow, 
    changeModalView, 
    setCategoryEdit,
    deleteFirebaseCategory
    } = useContext(AdminContext)
  const [categories, setcategories] = useState(sortedCategories)
  useEffect(() => {
    setcategories(sortedCategories);
  }, [setcategories, sortedCategories])
  
  /***Modal *****/
  const actionModal=async(category, action)=>{
    if(action==='editar'){
      await setCategoryEdit(category);
      await changeModalView('editarCategoria');
    }else{
      await setCategoryEdit('');
      await changeModalView('agregarCategoria'); 
    }
   
    await handleShow();
  } 
  

  return (
    <>
    <div className='servicesScreen-container'>
        <div className='agregar'> <FontAwesomeIcon className="AddButton" icon={ faPlusSquare} onClick={()=>actionModal(null, 'agregar')}/></div>
        <div className="table-admin">
            <div className="row header-admin">
                <div className="col-4">
                  Nombre
                </div>
                <div className="col-4">
                  Fecha de creación
                </div>
                <div className="col-4">
                  Acciones
                </div>
            </div>
            {
              (categories!==null) &&
              categories.map((category) =>{
                return (
                <>
                <hr style={{backgroundColor:"#ccc"}}/>
                <div className="row admin-row" key={category.id}> 
                    <div className="col-4">
                       {category.category}
                    </div>
                    <div className="col-4">
                      {category.date}
                    </div>
                    <div className="col-4">
                      <FontAwesomeIcon className="EditButton" icon={ faEdit} onClick={()=>actionModal(category, 'editar')}/>
                      <FontAwesomeIcon className="DeleteButton" icon={ faTrash} onClick={()=>deleteFirebaseCategory(category.id)}/>
                    </div>
                </div>
              </>)
              })
            }
        </div>
        <div className='pagination-admin-container'>
            <nav aria-label="Page navigation ">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
        </div>
    </div>
    <ActionsModal />
  </>
  )
}
