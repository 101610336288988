import React, { useContext, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import AdminContext from '../../context/admin/adminContext';
import '../../styles/Admin/modal.css'
import { CategoryForm } from './CategoryForm';
import { ProjectForm } from './ProjectForm';
import { ServiceForm } from './ServiceForm';
import { UserForm } from './UserForm';
export const ActionsModal = () => {
    const {show, handleClose, adminState} = useContext(AdminContext)
    const {modalScreen}=adminState;
    useEffect(() => {
      return
    }, [adminState])
    const closeModal=async()=>{
      await handleClose()
    }
  return (
    <>

      <Modal show={show} onHide={closeModal} className="modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {
              modalScreen==='editarUsuario' && 'Editar Usuario'
            }
            {
              modalScreen==='agregarCategoria' && 'Agregar Categoría'
            }
            {
              modalScreen==='editarCategoria' && 'Editar Categoría'
            }
            {
              modalScreen==='agregarProyecto' && 'Agregar Proyecto'
            }
            {
              modalScreen==='editarProyecto' && 'Editar Proyecto'
            }
            {
              modalScreen==='agregarServicio' && 'Agregar Servicio'
            }
            {
              modalScreen==='editarServicio' && 'Editar Servicio'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
              modalScreen==='editarUsuario' && (<UserForm />)
            }
            {
              (modalScreen==='agregarCategoria' || modalScreen==='editarCategoria') && (<CategoryForm />)
            }
            {
              (modalScreen==='agregarProyecto' || modalScreen==='editarProyecto') && (<ProjectForm />)
            }
             {
              (modalScreen==='agregarServicio' || modalScreen==='editarServicio') && (<ServiceForm />)
            }
        </Modal.Body>
      </Modal>
    </> 
  )
}
