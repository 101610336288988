/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Home/navbar.css'
import { useNavigate } from "react-router-dom";
import FirebaseContext from '../../context/firebase/firebaseContext'
export const Navbar = () => {
  const {firebaseState} = useContext(FirebaseContext);
  const [logged, setlogged] = useState(false)
  const [nosotros, setNosotros] = useState(false)
  const [servicios, setServicios] = useState(false)
  const [contacto, setContacto] = useState(false)
  const navigate=useNavigate();
  useEffect(() => {
    setlogged(firebaseState.isLogged)
  }, [setlogged,firebaseState])
  
  const agregarEtiqueta=(etiqueta)=>{
    setNosotros(false)
    setContacto(false)
    setServicios(false)
    if(etiqueta==="nosotros"){
      setNosotros(true)
      return 
    }
    if(etiqueta==="contacto"){
      setContacto(true)
      return 
    }
    if(etiqueta==="servicios"){
      setServicios(true)
      return 
    }
  }
  return (
    <>
    <nav className="navbar">
        <div className="navbar-container container">
            <input type="checkbox" name="" id="" />
            <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </div>
            <ul className="menu-items">
                <li><a href="#nosotros" id="etiquetaNosotros" className={nosotros ? "etiqueta" : undefined} onClick={()=>agregarEtiqueta("nosotros")}>Nosotros</a></li>
                <li><a href="#servicios" id="etiquetaServicios" className={servicios ? "etiqueta" : undefined} onClick={()=>agregarEtiqueta("servicios")}>Servicios</a></li>
                <li><a href="#contacto" id="etiquetaContacto" className={contacto ? "etiqueta" : undefined} onClick={()=>agregarEtiqueta("contacto")}>Contacto</a></li>
                { 
                  !logged 
                  ? <li onClick={()=>{navigate("/login", { replace: true }) }}><a >Iniciar sesión</a></li> 
                  : <li onClick={()=>{navigate("/", { replace: true }) }}><a>Dashboard</a></li>
                }
            </ul>
            
            <div className="logo">
              <h1>
              <img src="/assets/Logo transparencia-02.png" style={{width:"2.5em", heigh:"2.5em"}} alt="logo"/>
             </h1>
            </div>
        </div>
    </nav>
    </>
  )
}
