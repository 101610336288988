import React, { useContext, useEffect, useState } from 'react'
import '../../styles/Admin/lateralMenu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faProjectDiagram, faCubesStacked, faDashboard, faLayerGroup, faEdit} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
import { useNavigate } from "react-router-dom";
export const LateralMenu = () => {
  const {changeScreen, adminState} = useContext(AdminContext);
  const [active, setactive] = useState(adminState.activeScreen);
  const navigate=useNavigate();
  useEffect(() => {
    setactive(adminState.activeScreen)
  }, [setactive, adminState.activeScreen ])
  
  return (
    <>
    <input type="checkbox" id="check-menu"/>
    <label for="check-menu" className="pantalla-CerrarMenu"></label>
    <nav id="nav">
    	<label for="check-menu" className="menu-toogle"></label>
    	<div onClick={()=>{navigate("/home", { replace: true }) }} className="home">
    	 <div className="titulo">
          <div className="logo">
              <h1>
                <img src="/assets/Logo transparencia-02.png" style={{width:"2.5em", heigh:"2.5em"}} alt="logo"/>
              </h1>
          </div>
      	</div>
    	</div>
    	<div className='separator'></div>
    	<ul className="MenuNav">
        <li onClick={()=>{changeScreen("dashboard"); setactive("dashboard")}} className={`${active==="dashboard"? "selectedItem": ''}`}> <FontAwesomeIcon className="fontAwesomeIcon" icon={ faDashboard} /><div href="">Dashboard</div></li>
    		<li onClick={()=>{changeScreen("users"); setactive("users")}} className={`${active==="users"? "selectedItem": ''}`}> <FontAwesomeIcon className="fontAwesomeIcon" icon={ faUsers} /><div href="">Usuarios</div></li>
    		<li onClick={()=>{changeScreen("services"); setactive("services")}} className={`${active==="services"? "selectedItem": ''}`}> <FontAwesomeIcon className="fontAwesomeIcon" icon={faCubesStacked} /><div href="">Servicios</div></li>
    		<li onClick={()=>{changeScreen("home"); setactive("home")}} className={`${active==="home"? "selectedItem": ''}`}> <FontAwesomeIcon className="fontAwesomeIcon" icon={faEdit} /><div href="">Home</div></li>
    	</ul>
    
    </nav>
   
    </>
  )
}
