/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext} from 'react'
import '../styles/Login/login.css'
import { Link} from 'react-router-dom'
import { LoginRegisterNavbar } from '../components/ui/LoginRegisterNavbar'
import FirebaseContext from '../context/firebase/firebaseContext'
import { useForm } from '../hooks/useForm'
export const LoginScreen = () => {
	const {login, googlelogin} = useContext(FirebaseContext)
	const [formValues, handleInputChange]=useForm({email:'', password:''});
	const {email, password}=formValues
	async function submitHandler(e) {
		e.preventDefault();
		await login({email, password});
	}

  return (
    <>
    <LoginRegisterNavbar />
    <div className="img login-screen">
    	<section className="section">
    		<div className="container">
    			<div className="row justify-content-center">
    				<div className="col-md-6 text-center mb-5">
    					<h2 className="heading-section">Inicio de sesión</h2>
    				</div>
    			</div>
    			<div className="row justify-content-center">
    				<div className="col-md-6 col-lg-4">
    					<div className="login-wrap p-0">
    		      	<Link to="/register" ><h3 className="mb-4 text-center">¿No tienes una cuenta?</h3></Link>
    		      	<form action="#" className="signin-form">
    		      		<div className="form-group">
    		      			<input type="text" name="email" className="form-control" placeholder="correo" required value={email} onChange={handleInputChange} />
    		      		</div>
    	            <div className="form-group">
    	              <input id="password-field" name="password" type="password" className="form-control" placeholder="Contraseña" required value={password} onChange={handleInputChange}/>
    	            </div>
    	            <div className="form-group">
    	            	<button type="submit" className="form-control btn btn-primary submit px-3" onClick={submitHandler}>Iniciar sesión</button>
    	            </div>
    	            
    	          </form>
    	          <p className="w-100 text-center">&mdash; O Iniciar sesión con &mdash;</p>
    	          <div className="social d-flex text-center">
    	          	<a href="#" className="px-2 py-2 mr-md-1 rounded fa fa-google" onClick={()=>googlelogin()}> Google</a>

    	          </div>
    		      </div>
    				</div>
    			</div>
    		</div>
    	</section>
	</div>
	</>
  )
}

