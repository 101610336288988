import React, { useReducer } from 'react';
import FirebaseReducer from './firebaseReducer';
import FirebaseContext from './firebaseContext'
import { types } from '../../types';
import { firebase, db, auth, googleAuthProvider } from '../../firebase/firebase-config';
import {  doc, setDoc, getDoc } from 'firebase/firestore';
import Swal from "sweetalert2";
import {
    signInWithPopup,
    signInWithEmailAndPassword,
    updateProfile,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signOut
} from "firebase/auth";

import { myDate } from '../../helpers/Date.js';
const FirebaseState=(props)=>{

    const initialState={
        user:{
          name:'',
          rol:'',
          email:'',
          id:''
        },
        rol:"",
        isLogged:false
        
    }
    
    const [state, dispatch]=useReducer(FirebaseReducer, initialState)
    const setLoggedOut=()=>{
      dispatch({
        type:types.loggedOut
      
      })
    }
    const login=async(data)=>{
        signInWithEmailAndPassword(auth, data.email, data.password)
        .then((result) => {
            Login(result)
        })
        .catch((e) => {
            console.log(e);
            if (e.code === 'auth/wrong-password') {
                Swal.fire("Error", "El usuario y contraseña es incorrecta", "error");
            }
            if (e.code === 'auth/user-not-found') {
                Swal.fire("Error", "El usuario no existe", "error");
            }
      });
    }
    
    const googlelogin=()=>{
        googleAuthProvider.setCustomParameters({prompt: "select_account"})
        signInWithPopup(auth, googleAuthProvider)
        .then(async(result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          
          // The signed-in user info.
          const user = result.user;
          const docuRef = await doc(db, `users/${user.uid}`);
          const docSnap = await getDoc(docuRef);
          if (docSnap.exists()) {
            //Login(docSnap.data())
          } else {
            const date=myDate().split(" ")[0].split("/").reverse().join("/")
            setDoc(docuRef, { name: user.displayName, email: user.email, rol: "user", date:date });
            Login({name: user.displayName, email: user.email, rol: "user", date:date})
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }
    const register=async(data)=>{
        const name=data.name+" "+data.lastname;
        const rol="user";
        await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.password,
            name,
            rol
          ).then(({ user }) => {
            updateProfile(user, { displayName: name });
            const docuRef = doc(db, `users/${user.uid}`);
            const date=myDate().split(" ")[0].split("/").reverse().join("/");
            setDoc(docuRef, { name: name, email: data.email, rol: rol, date: date });
           
          }).then((usuarioFirebase) => {
            return usuarioFirebase;
          }).catch((e) => {
            console.log(e);
            if (e.code === 'auth/email-already-in-use') {
              Swal.fire("Error", "El usuario ya existe", "error");
            }
          });
    }
    const Login=(user)=>{
        dispatch({
            type:types.login,
            payload:{
                user: user,
                rol:user.rol
            }
        })
    }
    const startLogOut=async()=>{
      await signOut(auth).then(() => {
        logOut()
      }).catch((error) => {
        console.log(error);
      });
      logOut()
    }
    const logOut=()=>({
            type:types.logout
    })
    return (
        <FirebaseContext.Provider
            value={{
            firebaseState:state,
            firebase,
            login,
            logOut,
            googlelogin,
            register, 
            startLogOut,
            Login,
            setLoggedOut
            }}
        >
            {props.children}
        </FirebaseContext.Provider>
    
    )
}

export default FirebaseState;