/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrash, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
import { ActionsModal } from './ActionsModal'
export const ServicesScreen = () => {
  const {
    sortedServices, 
    handleShow, 
    changeModalView, 
    setServiceEdit,
    deleteFirebaseService
    } = useContext(AdminContext)
  const [services, setservices] = useState(sortedServices)
  useEffect(() => {
    setservices(sortedServices);
  }, [setservices, sortedServices])
  
  /***Modal *****/
  const actionModal=async(service, action)=>{
      if(action==='editar'){
        await setServiceEdit(service);
        await changeModalView('editarServicio');
        await handleShow();
      }else{
        await setServiceEdit('');
        await changeModalView('agregarServicio'); 
        await handleShow();
      }
  } 
  return (
  <>
    <div className='servicesScreen-container'>
          <div className='agregar'> 
          Para que esta sección sea visible en la Landing Page, vaya a la sección de home en el menú lateral, click en servicios y agregue una imagen.
          <FontAwesomeIcon className="AddButton" icon={ faPlusSquare} onClick={()=>actionModal(null, 'agregar')}/></div>
          <div className="table-admin">
            <div className="row header-admin">
                <div className="col-2">
                  Nombre
                </div>
                <div className="col-4">
                 Descripción
                </div>
                <div className="col-3">
                  Fecha de creación
                </div>
                <div className="col-3">
                  Acciones
                </div>
            </div>
            {
              (services!==null) &&
              services.map((service) =>{
                return (
                <>
                <hr style={{backgroundColor:"#ccc"}}/>
                <div className="row admin-row" key={service.id}>
                    <div className="col-2">
                        {service.name}
                    </div>
                    <div className="col-4">
                        {service.description}
                    </div>
                    <div className="col-3">
                        {service.date}
                    </div>
                    <div className="col-3">
                      <FontAwesomeIcon className="EditButton" icon={ faEdit} onClick={()=>actionModal(service, 'editar')} />
                      <FontAwesomeIcon className="DeleteButton" icon={ faTrash} onClick={()=>deleteFirebaseService(service.id)}/>
                    </div>
                </div>
                </>)
              }
            )}
          </div>
        <div className='pagination-admin-container'>
            <nav aria-label="Page navigation ">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
        </div>
    </div>
    <ActionsModal/>
  </>
  )
}
