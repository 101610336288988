import React, { useContext} from 'react'
import FirebaseContext from '../../context/firebase/firebaseContext'
import '../../styles/Admin/welcomeAdminCard.css'
export const WelcomeCardAdmin = () => {
  const {firebaseState} = useContext(FirebaseContext);
  
  return (
    <div className="welcomeAdmin-container" 
        style={{backgroundImage: `url("/assets/blue-bubbles_1600.jpg")` }}>
        <div>Bienvenido,</div>
        <div className='adminCardName'>{firebaseState.user.name}</div>
        <div>Nos alegra tenerte de vuelta</div>
    </div>
  )
  
}
