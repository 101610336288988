import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import homeAdminContext from '../../../context/homeAdmin/homeAdminContext'
import { ModalImages } from './ModalImages'
export const CarouselScreen = () => {
    const {
        sortedCarousel, 
        handleShow,  
        deleteFirebaseCarouselImage,
        changeScreen
        } = useContext(homeAdminContext)
      const [images, setImages] = useState(sortedCarousel)
      useEffect(() => {
        setImages(sortedCarousel);
      }, [setImages, sortedCarousel])
      
      /***Modal *****/
      const actionModal=async()=>{
            await changeScreen('carousel'); 
            await handleShow();
      } 

  return (
    <>
<div className='agregar' style={{padding:'2em', color:'#fff'}}>Tamaño mínimo recomendado: 1024x576px<FontAwesomeIcon className="AddButton"icon={ faPlusSquare} onClick={()=>actionModal()}/></div>
<main className="carousel-container">
        {
            (images!==null) &&
              images.map((image) =>{
                return (
     
             <section className="cardCarousel" key={image.id}  style={{backgroundImage: `url(${image.url})` }}>
                
                 <div className="texts">
                     <button className='eliminar'type="button" onClick={()=>deleteFirebaseCarouselImage(image.id)}><FontAwesomeIcon  icon={ faTrash} />Eliminar</button>
                </div>
             </section>)
            })
        }
    
</main>
    <ModalImages />
    </>
  )
}
