/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrash, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
import { ActionsModal } from './ActionsModal'
import Swal from "sweetalert2";
export const ProjectsScreen = () => {
  const {
    sortedProjects, 
    sortedCategories,
    handleShow, 
    changeModalView, 
    setProjectEdit,
    deleteFirebaseProject
    } = useContext(AdminContext)
  const [projects, setprojects] = useState(sortedProjects)
  const [categories, setcategories] = useState(sortedCategories)
  useEffect(() => {
    setprojects(sortedProjects);
    setcategories(sortedCategories);
  }, [setprojects, sortedProjects, categories, setcategories, sortedCategories])
  
  /***Modal *****/
  const actionModal=async(project, action)=>{
    if(categories!==null && categories!==undefined && categories.length !==0 ){
      if(action==='editar'){
        await setProjectEdit(project);
        await changeModalView('editarProyecto');
        await handleShow();
      }else{
        await setProjectEdit('');
        await changeModalView('agregarProyecto'); 
        await handleShow();
      }
    }else{
      Swal.fire("Error", "Debe agregar al menos una categoría antes de crear un proyecto", "error");
    }
  } 

  return (
    <>
    <div className='projectsScreen-container'>
        <div className='agregar'> <FontAwesomeIcon className="AddButton" icon={ faPlusSquare} onClick={()=>actionModal(null, 'agregar')}/></div>
        <div className="table-admin">
            <div className="row header-admin">
                <div className="col-3 ">
                  Nombre
                </div>
                <div className="col-3 ">
                  Categoría
                </div>
                <div className="col-3 ">
                  Fecha de creación
                </div>
                <div className="col-3 ">
                  Acciones
                </div>
            </div>
            {
              (projects!==null) &&
              projects.map((project) =>{
                return (
                <>
                <hr style={{backgroundColor:"#ccc"}}/>
                <div className="row admin-row" key={project.id}>
                    <div className="col-3 ">
                      { project.name}
                    </div>
                    <div className="col-3 ">
                      {project.category}
                    </div>
                    <div className="col-3 ">
                      {project.date}
                    </div>
                    <div className="col-3">
                      <FontAwesomeIcon className="EditButton" icon={ faEdit} onClick={()=>actionModal(project, 'editar')}/>
                      <FontAwesomeIcon className="DeleteButton" icon={ faTrash} onClick={()=>deleteFirebaseProject(project.id)}/>
                    </div>
                </div>
                </>
                )}
              )
            }
        </div>
        <div className='pagination-admin-container'>
            <nav aria-label="Page navigation ">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
        </div>
    </div>
    <ActionsModal />
  </>
  )
}
