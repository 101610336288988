import React from 'react'
import { BodyUser } from '../components/User/BodyUser'
import { LateralMenu } from '../components/User/LateralMenu'

export const UserView = () => {
  return (
    <div className='admin-view-container'>
      <div>
        <LateralMenu />
      </div>
      <div>
        <BodyUser />
      </div>
    </div>
  )
}
