/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import '../../styles/Admin/dashboardCards.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUsers, faProjectDiagram, faCubesStacked, faLayerGroup} from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../../context/admin/adminContext'
export const AdminDashboardCards = () => {
    const {changeScreen, sortedusers, sortedServices} = useContext(AdminContext)
    const [userNumber, setuserNumber] = useState(0);
    const [servicesNumber, setservicesNumber] = useState(0)
    useEffect(() => {
        if(sortedusers!==null) setuserNumber(sortedusers.length)

        
        if(sortedServices!==null) setservicesNumber(sortedServices.length)
        
    }, [sortedusers, sortedServices])
    
  return (
    <div  className="app">
        <ul className="list" style={{display: "contents"}}>
            <li style={{display:"block"}} onClick={()=>{changeScreen("users")}}>
                <a className="card tile text-dark">
                    <div className="card-header topper"></div>
                    <div className="card-body flex-column">
                        <div className="display-4 Title">Usuarios</div>
                        <FontAwesomeIcon className="fontAwesomeIcon" icon={ faUsers} />
                        <div>{userNumber}</div>
                    </div>
                </a>
            </li>
           
            <li style={{display:"block"}} onClick={()=>{changeScreen("services")}}>
                <a className="card tile text-dark">
                    <div className="card-header topper"></div>
                    <div className="card-body flex-column">
                        <div className="display-4 Title">Servicios</div>
                        <FontAwesomeIcon className="fontAwesomeIcon" icon={ faCubesStacked} />
                        <div>{servicesNumber}</div>
                    </div>
                </a>
            </li>
           
        </ul>
    </div>
  
  )
}
