import { types } from "../../types";

const homeAdminReducer=(state, action)=>{
    switch (action.type) {
        case types.changeHomeScreen:
            return {
                ...state,
                modalScreen:action.payload
            }
        default:
            return state;
    }

}
export default homeAdminReducer;