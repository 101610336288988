import React, { useContext } from 'react'
import AdminContext from '../../context/admin/adminContext'
import { Dashboard } from './Dashboard'
import { HeaderAdmin } from './HeaderAdmin'
import { UsersScreen } from './UsersScreen'
import { ProjectsScreen } from './ProjectsScreen'
import { HomeScreen } from './HomeScreen'
import { ServicesScreen } from './ServicesScreen'
import { CategoriesScreen } from './CategoriesScreen'
import '../../styles/Admin/tablesAndPagination.css'
export const BodyAdmin = () => {
  const {adminState} = useContext(AdminContext)
  const {activeScreen}=adminState;
  
  return (
    <>
    
    <label for="check-menu" className="menu-toogle"></label>
    
    <div className="cuerpo">
        <HeaderAdmin />
        <div style={{marginTop:"2em"}}>
        {
          activeScreen==='dashboard' &&  <Dashboard />
        }
        {
          activeScreen==='users' && <UsersScreen />
        }
        {
          activeScreen==='projects' && <ProjectsScreen />
        }
        {
          activeScreen==='home' && <HomeScreen />
        }
        {
          activeScreen==='services' && <ServicesScreen />
        }
        {
          activeScreen==='categories' && <CategoriesScreen />
        }
        </div>
    </div>
    
    </>
  )
}
