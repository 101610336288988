import React from 'react'
import { BodyAdmin } from '../components/Admin/BodyAdmin'
import { LateralMenu } from '../components/Admin/LateralMenu'
import AdminState from '../context/admin/adminState'
import HomeAdminState from '../context/homeAdmin/homeAdminState'

export const AdminView = () => {
  return (
  <AdminState >
  <HomeAdminState>
    <div className='admin-view-container'>
      <div>
        <LateralMenu />
      </div>
      <div>
        <BodyAdmin />
      </div>
    </div>
  </HomeAdminState>
  </AdminState>
  )
}
