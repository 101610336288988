/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons'
import FirebaseContext from '../../context/firebase/firebaseContext'
import AdminContext from '../../context/admin/adminContext'
export const HeaderAdmin = () => {
  const {startLogOut, firebaseState, logOut} = useContext(FirebaseContext);
  const {changeScreen, adminState} = useContext(AdminContext)
  const [active, setactive] = useState(adminState.activeScreen)
  useEffect(() => {
    setactive(adminState.activeScreen)
  }, [setactive, adminState.activeScreen ])
  return (
    <div className='HeaderAdmin'>
        <div><FontAwesomeIcon className="fontAwesomeIcon" icon={ faHome} onClick={()=>{changeScreen('dashboard')}}/>/{active}</div>
        <div>
            <span><FontAwesomeIcon className="fontAwesomeIcon" icon={ faBell} /></span>
            <span> <FontAwesomeIcon className="fontAwesomeIcon" icon={ faUserCircle} />{firebaseState.user.name}</span>
            <button onClick={async()=>{
              startLogOut();
              await logOut();
              }} style={{backgroundColor:"transparent", border:"none", fontSize:"12px", color:"#fff", fontWeight:"bold"}}><a >Cerrar sesión</a></button>
            
       </div>
    </div>
  )
}
