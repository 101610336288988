import React from 'react'
import { AboutUs } from '../components/Home/AboutUs'
import { CarouselComponent } from '../components/Home/Carousel'
import { Contact } from '../components/Home/Contact'
import { MisionVisionValores } from '../components/Home/MisionVisionValores'
import { Navbar } from '../components/Home/Navbar'
import { Projects } from '../components/Home/Projects'
import { Servicios } from '../components/Home/Servicios'
import AdminState from '../context/admin/adminState'
import HomeAdminState from '../context/homeAdmin/homeAdminState'

export const Home = () => {
  
  return (
    <>
      <HomeAdminState>
      <AdminState>
        <Navbar />
        <CarouselComponent />
       
        <AboutUs />
        <Servicios />
        <Contact />
      </AdminState>
      </HomeAdminState>
    </>
  )
}
