import React, { useContext} from 'react';
import AdminContext from '../../context/admin/adminContext';
import { useForm } from '../../hooks/useForm';
import Swal from "sweetalert2";
export const CategoryForm = () => {
    const {handleClose, adminState, editFirebaseCategory, addFirebaseCategory} = useContext(AdminContext)
    const {modalScreen, category}=adminState;
    // manejo del formulario
    let initialState={categoryName:''}
    if(modalScreen==='editarCategoria'){
        initialState={categoryName:category.category}
    }
    
    let [formValues, handleInputChange, reset]=useForm(initialState);
    let {categoryName}=formValues;
    
    const closeModal=async(e)=>{
      e.preventDefault();
      await handleClose()
    }
    const handleSubmit=async(e)=>{
        e.preventDefault();
        if(categoryName.trim()===''){
          Swal.fire("Error", "Todos los campos son obligatorios", "error");
          return
        }
        if(modalScreen==='editarCategoria'){
            await editFirebaseCategory({category: categoryName, id:category.id});
        }else{
            await addFirebaseCategory({category: categoryName});
        }
        reset({category:''});
        handleClose();
    }
  return (
    <>
    <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
            <input type="text" className="form-control" name="categoryName" placeholder="Nombre de la categoría" value={categoryName} onChange={handleInputChange}/>
        </div>
          <div className="col-12" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <button type="submit" className="btn btn-primary btnAccept">{modalScreen==='editarCategoria'? 'Editar' : 'Agregar'}</button>
            
            <button className="btn btn-primary btnCancel" onClick={closeModal}>
                Cancelar
            </button>
          </div>
    </form>
    </>
  )
}
